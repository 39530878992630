import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPurchaseInformation(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('purchaseInformation')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('user', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateSetting/1`, data)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers", { params: queryParams })
          .get('customer_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchCustomerHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customer_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('customer_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('customer_headers/customers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //INVENTORY SETTINGS

    fetchInventoryHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventory_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInventoryHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventory_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventoryColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('inventory_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInventoryList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('inventory_headers/inventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //SALE SETTINGS

    fetchSaleHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('sale_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSaleHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`sale_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSaleColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('sale_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSaleList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('sale_headers/quotes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //PURCHASE SETTINGS

    fetchPurchaseHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchase_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchPurchaseHeadersbyId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchase_headers/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_columns", { params: queryParams })
          .get('purchase_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchPurchaseList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('purchase_headers/quotes', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    /********* Roles */
    /*********** */
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    addRole(ctx, role) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('roles/store', role)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateRole(payload) {
      return new Promise((resolve, reject) => {
        console.log(payload);
        axiosIns
          .put(`roles/${payload.role}`, payload.permissions)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
  },
};
